@primary-color: #0089c4;
@dim-color: #0089c4;
@mea-color: #229445;
.filter-dialog {
  .ant-card {
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    height: 50vh;
  }

  .filter-aside {
    width: 40%;
    min-width: 230px;
    max-width: 260px;
    height: 100%;
    min-height: 100px;
    .ant-tabs .ant-tabs-top-content,
    .ant-tabs .ant-tabs-bottom-content {
      position: relative;
      height: calc(100% - 60px);
      & > .ant-tabs-tabpane {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .filter-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  .filter-dialog-tabs {
    height: 100%;
    padding: 10px;
    padding-top: 0;
    border: 1px solid #e6e6e6;
  }

  .tab-dataset,
  .tab-assembly {
    .cube-field-search,
    .tab-assembly-search {
      padding: 8px 0;
    }
    .cube-field-list,
    .tab-assembly-field-list {
      overflow-y: auto;
      .item-field {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 26px;
        font-size: 12px;
        color: rgba(0,0,0,0.65);
        cursor: pointer;
        &:hover {
          background-color: #f5f7fa;
        }
        &.checked {
          background-color: @primary-color;
          color: #fff;
          cursor: not-allowed;
          .svg-icon-wrapper {
            color: #fff !important;
          }
        }
        .svg-icon-wrapper {
          display: flex;
          align-items: center;
          height: 12px;
          margin-left: 4px;
          margin-right: 4px;
          font-size: 12px;
          &.dimension {
            color: @dim-color;
          }
          &.measure {
            color: @mea-color;
          }
        }
      }
    }
  }

  .tab-dataset,
  .tab-assembly {
    .cube-loading-spin {
      .ant-spin-text {
        font-size: 12px;
      }
    }
    .ant-spin-container {
      height: 100%;
    }
    position: relative;
    height: 100%;
    .ant-select,
    .ant-input {
      font-size: 12px;
    }
    .cube-switch {
      display: flex;
      .ant-select {
        flex: 1;
      }
    }
    .cube-field-wrapper {
      height: 100%;
    }
  }

  .tab-dataset {
    .cube-loading-spin {
      height: calc(100% - 24px);
    }
    .cube-field-list {
      height: calc(100% - 40px);
    }
  }

  .tab-assembly {
    .cube-loading-spin {
      height: calc(100% - 60px);
    }
    .tab-assembly-field-list {
      height: 100%;
    }
  }

  .tab-assembly {
    .item-comp {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 30px;
      height: 26px;
      font-size: 12px;
      color: rgba(0,0,0,0.65);
      cursor: pointer;
      i.component-type-icon {
        transform: scale(0.4);
      }
      .chart-name {
        flex: 1;
      }
      .svg-icon-wrapper {
        opacity: 0.5;
      }
      &:hover {
        background-color: #f5f7fa;
      }
    }
  }

  .filter-head {
    position: relative;
    width: 100%;
    height: 40px;
    border: 1px solid #e6e6e6;
    .filter-field {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 100%;
      padding: 0 8px;
    }
    .drag-items-placeholder {
      position: absolute;
      top: 0;
      left: 8px;
      line-height: 38px;
      font-size: 12px;
      color: #9ea6b2;
    }
    .drag-item-field {
      display: flex;
      align-items: center;
      padding: 0 4px;
      height: 24px;
      border-radius: 2px;
      font-size: 12px;
      &.dimension {
        border: 1px solid @dim-color;
        background-color: fade(@dim-color, 15%);
        color: @dim-color;
        i.icon-del:hover {
          background-color: @dim-color;
        }
      }
      &.measure {
        border: 1px solid @mea-color;
        background-color: fade(@mea-color, 15%);
        color: @mea-color;
        i.icon-del:hover {
          background-color: @mea-color;
        }
      }
      .field-caption {
        .svg-icon-wrapper {
          margin-right: 4px;
        }
      }
      i.icon-del {
        margin-left: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        cursor: pointer;
        border-radius: 50%;
        font-size: 10px;
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .filter-content {
    flex: 1;
    height: calc(50vh - 120px);
  }

  .filter-control {
    .ant-checkbox-wrapper {
      font-size: 12px;
    }
    .filter-options-left,
    .filter-options-right {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      height: 50px;
      .svg-icon-wrapper {
        cursor: pointer;
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
      .show-switch {
        display: flex;
        align-items: center;
        gap: 8px;
        label {
          font-size: 12px;
        }
      }
    }
    .filter-options-right {
      justify-content: flex-end;
    }
  }
}

.ib-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 5px 2px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::before {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
    &:hover {
      background-color: fade(@primary-color, 15%);
      color: @primary-color;
    }
    &.ib-active-li {
      &::before {
        background: @primary-color;
      }
    }
  }
}

.custom-component-class {
  width: 100%;
  .ant-select {
    width: 100%;
  }
}

.pop-option-title {
  textarea {
    resize: none;
    font-size: 12px;
  }
  .word-limit {
    position: absolute;
    bottom: 16px;
    right: 22px;
    font-size: 12px;
    color: #999;
  }
}

.pop-option-range {
  .view-ids-container {
    position: relative;
    width: 200px;
    min-height: 150px;
    max-height: 200px;
    overflow-y: auto;
  }
  .icontext {
    position: relative;
    display: inline-flex;
    align-items: center;
    min-height: 18px;
    .chart-name {
      display: inline-block;
      padding-left: 30px;
      min-height: 18px;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

@primary-color: #0089C4;