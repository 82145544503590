@import './variables.less';
.data-panel-field-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .areas-wrapper {
    overflow-y: auto;
  }
}

.field-area-wrapper,
.map-range-wrapper {
  position: relative;
  padding: 8px 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid @border-color;
  font-size: 12px;
  color: @color-white;

  .field-area-header,
  .map-range-header {
    display: flex;
    justify-content: space-between;
    margin: 4px 0 8px;

    .area-name {
      .require-icon {
        margin-right: 4px;
        color: #f23c3c;
        transform: scale(1.5);
      }
    }
  }

  .field-box-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 4px;
    height: 24px;
    border-radius: 2px;
    overflow: hidden;
    font-size: 12px;
    &.dimension-field-box {
      border: 1px solid @dimension-color;
      background-color: rgba(0, 137, 196, 0.15);

      .field-icon {
        .svg-icon-wrapper {
          color: @dimension-color;
        }
      }
    }
    &.measure-field-box {
      border: 1px solid @measure-color;
      background-color: rgba(34, 148, 69, 0.15);

      .field-icon {
        .svg-icon-wrapper {
          color: @measure-color;
        }
      }
    }
    &:hover {
      .right-hover-icons {
        opacity: 1;
      }
    }

    .field-icon,
    .sort-icon {
      display: flex;
      margin-right: 4px;
    }

    .field-caption {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .right-hover-icons {
      font-size: 14px;
      color:rgba(255, 255, 255, 0.5);
      white-space: nowrap;
      opacity: 0;

      .icon-wrapper {
        margin-left: 3px;
        cursor: pointer;
      }
    }

    .sort-icon {
      .svg-icon-wrapper {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.35);
      }
    }

    .chart-type-icon {
      margin-left: 3px;
    }

    .selected-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      &.light {
        background-color: rgba(0,0,0,0.09);
        color: rgba(0,0,0,0.45);
      }
    }
  }
}

.map-range-wrapper {
  padding: 8px 0;
}

.map-range-tree-select {
  .ant-select-tree {
    font-size: 12px;
    li {
      margin: 4px 0;
    }
  }
}

.branch-tag-wrapper {
  padding: 8px 12px;

  .ant-radio-group {
    .ant-radio-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      font-size: 12px;
      color: hsla(0,0%,0%,.6);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.auto-refresh-wrapper,
.update-wrapper {
  .ant-input-number {
    padding: 0 8px;
    width: 60px;
    height: 24px;
    font-size: 12px;
    border: 1px solid #e6e6e6;
    background: #fff;
    font-size: 12px;
    color: #1f2329;
  }

  .ant-input-number-input {
    padding: 0;
    height: 22px;
    line-height: 22px;
  }
}

.auto-refresh-wrapper {
  display: flex;
  align-items: center;
  padding: 12px;
  height: 48px;

  .auto-refresh-time-number {
    flex: 1;
    margin-right: 8px;
    height: 24px;
  }

  .ant-input-number {
    width: 54px;
  }
}

.update-wrapper {
  margin-top: auto;
  color: @color-white;
  .result-display {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.1);

    .title {
      margin-right: 8px;
      font-size: 12px;
      color: @color-white;
    }
  }

  .ant-btn-block {
    border-radius: 0 !important;
  }
}

.field-dropdown-menu,
.field-dropdown-submenu {
  &.dark-theme {
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      line-height: 16px;
      color: rgba(255, 255, 255, 0.75);
      font-size: 12px;
      .anticon {
        color: rgba(255, 255, 255, 0.75);
      }
      &:hover {
        background-color: #293451;
      }
    }
    color: rgba(255, 255, 255, 0.75);
    .ant-dropdown-menu,
    .ant-dropdown-menu-submenu {
      background-color: #081226;
    }

    .ant-dropdown-menu-submenu:hover,
    .ant-dropdown-menu-item-selected {
      background-color: #293451;
      color: @color-white;
    }
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    line-height: 16px;
    font-size: 12px;
  }
}

.chart-type-dropdown-menu {
  .ant-dropdown-menu-item {
    i.svg-icon-wrapper {
      margin-right: 4px;
    }
  }
}

.modal-display-format {
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    & > label {
      color: rgba(0,0,0,0.65);
    }
  }
}

@primary-color: #0089C4;