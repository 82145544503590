// @import './style/theme/main-3.23.1.min.css';
/* 覆盖lib库里的筛选组件样式 */
@import '~antd/dist/antd.less';
@primary-color: #0089c4;

body {
  overflow: auto;
}

.ud-filter {
  .ant-form {
    .ant-form-item-label {
      max-width: 110px;
      label {
        display: initial;
        line-height: inherit;
      }
    }
  }
}

.ud-main {
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.ud-main-content {
  padding: 0 15px;
  overflow: auto;
  flex: 1;
  background: #f7faff;
  &.no-sidebar {
    //   overflow: hidden;
  }
}
/* 覆盖菜单样式 */
.ud-sidebar {
  background-color: #fff;
  box-shadow: 2px 0 6px #efefef;
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 20px #dadada;
  }
  .ud-sidebar-title {
    // background-color: rgba(0, 40, 77, 1);
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    height: 64px;
    border-bottom: 0;
  }
  .ud-menu {
    .ant-menu {
      &:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
          background-color: #e6f7ff;
          color: #0089c4;
          border-right: 4px solid #0089c4;
        }
      }
      &.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
      .ant-menu-sub.ant-menu-inline > .ant-menu-item {
        height: 40px;
        line-height: 40px;
      }
      .ant-menu-item {
        color: #535353;
        height: 40px;
        line-height: 40px;
        &:hover {
          background-color: #e6f7ff;
          color: #0089c4;
        }
      }
      .ant-menu-submenu-title {
        color: #535353;
        &:hover {
          background-color: #e6f7ff;
          color: #0089c4;
        }
      }
      .ant-menu-submenu {
        > .ant-menu {
          background-color: #fff;
          // box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
          .ant-menu-item {
            color: #535353;
            &:hover,
            &.ant-menu-item-selected {
              color: #0089c4;
            }
          }
        }
        .ant-menu-submenu-arrow {
          &::before,
          &::after {
            background-color: rgba(0, 0, 0, 0.45);
            background-image: none;
          }
        }
      }
    }
    .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
            color: #0089c4;
        }
    }
  }
}
.ud-table {
  /* 覆盖内嵌表格样式 */
  tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: -11px -15px;
  }
  &.nowarp-th {
    thead {
      tr {
        th {
          white-space: nowrap;
        }
      }
    }
  }
  table {
    td.ellipsis-td {
      div {
        position: relative;
        height: 38px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
/* table列金额 统计数值组件 样式 */
table {
  td {
    .ant-statistic,
    .ant-statistic-content,
    .ant-statistic-content-value-decimal {
      font-size: inherit;
      color: inherit;
    }
    .red {
      color: red;
    }
  }
}
/* 覆盖TreeSelect搜索框 */
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  border-radius: var(--border-radius-base);
}
/* 覆盖详情页tabs样式 */
.detail-page .detail-body .ant-tabs .ant-tabs-content {
  padding: 0;
  border: none;
}
/* 修改下拉框使用搜索icon的样式 */
.ud-search-select.ant-select-open .ant-select-arrow-icon svg {
  transform: none;
}
/* end */
.normal-nowrap {
  white-space: normal !important;
}
/* 详情界面块区域标题样式 */
.ud-detail {
  > .ant-card {
    .ant-card-head {
      background-color: #f5f6fa;
    }
  }
}
/* 页面头部标题 */
.ud-page-header {
  font-size: 14px;
  .ud-page-header-heading-title {
    font-weight: bold;
  }
  .ud-page-header-heading-sub-title {
    .ant-tag {
      margin-top: -4px;
    }
  }
}
/* 详情页label左对齐样式覆盖 */
.left-align-label {
  .item-title {
    width: auto;
    min-width: auto;
  }
}

.ud-page-toolbar-affix {
  margin-bottom: 12px;
}

/* 滚动table横向滚动条高度 */
.ant-table-scroll {
  .ant-table-body {
    &::-webkit-scrollbar {
      height: 8px;
    }
  }
}

.list-status-circular {
  display: flex;
  align-items: center;
  &::before {
    content: '.';
    display: inline-block;
    margin-right: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    color: transparent;
    background: #00a854;
  }
  &.list-status-circular-disable {
    &::before {
      background: #f04134;
    }
  }
  &.list-status-circular-gray {
    &::before {
      background: rgba(121, 121, 121, 0.447058823529412);
    }
  }
}

.ant-form-item-label {
  span {
    font-size: 14px;
  }
}
.ant-back-top {
  right: 50px;
}

.ant-btn,
.ant-input,
.ant-select-selection {
  border-radius: 2px !important;
}

.ud-filter {
  padding-bottom: 15px;
  border: none;
}

.ud-filter .ud-filter-footer {
  justify-content: flex-end;
  border: none;
  padding-right: 20px;
}

// 覆盖列表header样式

.ud-page.list-page {
  .ud-page-header-affix {
    // margin: 15px 0 0 0;
    .ud-page-header {
      padding-left: 5px;
      margin-bottom: 0;
      border: none;
    }
  }
}
// 覆盖列表操作按钮样式
.ant-table-row {
  .td-operate {
    a {
      color: rgba(24, 144, 255, 1);
    }
  }
}

.action-button {
  padding: 0 4px !important;
  font-size: 16px !important;
  color: rgba(0,0,0,0.55) !important;
  cursor: pointer;
  &:hover {
    color: @primary-color !important;
  }
  &.enable {
    color: #00a854 !important;
  }
  &.disabled:hover  {
    color: red !important;
  }
}
.item-report-tpl {
  .action-button {
    font-size: 16px !important;
  }
}
.dropdown-action-button {
  &:hover,
  &:hover .action-button {
    color: @primary-color !important;
  }
  &.disabled:hover,
  &.disabled:hover .action-button {
    color: red !important;
  }
}

// 覆盖 arco design 默认主题
.arco-picker {
  background-color: #fff !important;
  border-color: #d9d9d9 !important;
}
.arco-picker-cell-selected .arco-picker-date-value,
.arco-picker-cell-selected:hover .arco-picker-date-value,
.arco-picker-cell-today::after,
.arco-btn-primary:not(.arco-btn-disabled) {
  background-color: @primary-color !important;
}
.arco-btn-primary.arco-btn-disabled {
  background-color: fade(@primary-color, 50%) !important;
}
.arco-picker-focused,
.arco-picker-focused:hover {
  border-color: @primary-color !important;
}
.arco-picker-size-default input {
  font-size: 12px !important;
}
.arco-btn-text:not(.arco-btn-disabled) {
  // color: @primary-color !important;
}

html .ant-checkbox-wrapper.checkbox-small {
  font-size: 12px;
  span {
    font-size: 12px;
  }
  .ant-checkbox-inner {
    width: 12px;
    height: 12px;
  }
  .ant-checkbox + span {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.map-range-tree-select {
  .ant-select-tree {
    font-size: 12px;
    li {
      margin: 0;
    }
  }
  &.dark {
    & > div {
      background-color: #1d3047;
    }
    background-color: #1d3047;
    color: rgba(255, 255, 255, 0.75);
    .ant-select-tree,
    .ant-select-tree li .ant-select-tree-node-content-wrapper {
      color: rgba(255, 255, 255, 0.75);
    }
    .ant-select-tree li .ant-select-tree-node-content-wrapper:hover,
    .ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      background-color: rgba(255, 255, 255, 0.1);
    }
    .ant-select-tree li .ant-select-tree-node-content-wrapper {
      border-radius: 0;
    }
  }
}

/** 列表查询页公共样式 **/
.list-container {
  position: relative;
  height: 100%;
  .location {
    padding: 15px 0;
  }

  .search-form {
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    background: #fff;
    .ant-form {
      display: flex;
      .ant-form-item {
        margin-bottom: 0;
        margin-right: 12px;
        display: flex;
        .ant-form-item-label {
          & > label {
            color: rgba(0,0,0,0.55);
          }
        }
      }
    }
    .button-group {
      display: flex;
      gap: 8px;
    }
  }

  .list-status {
    display: flex;
    align-items: center;
    .list-status-dot {
      display: inline-block;
      margin-right: 5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      &.status-success {
        background-color: #00a854;
      }
      &.status-error {
        background-color: #f04134;
      }
    }
  }
}

@primary-color: #0089C4;