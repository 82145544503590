.filter-group-wrapper {
  display: flex;
}

.item-filter {
  position: relative;
  padding: 0 16px;

  .filter-separate {
    position: absolute;
    top: 24px;
    left: 0;
    width: 1px;
    height: 22px;
    background-color: #e6e6e6;
  }

  .filter-header {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: rgba(0,0,0,0.6);
  }

  .filter-content {
    display: flex;
  }

  .filter-widget {
    display: flex;
    align-items: center;
    margin: 0 2px;
    cursor: pointer;
    .filter-widget-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      .svg-icon-wrapper {
        font-size: 22px;
      }
    }
    &.time-filter {
      color: #0089c4;
    }
    &.text-filter {
      color: #23beef;
    }
    &.number-filter {
      color: #37b4aa;
    }
    &.button-filter {
      color: #0089c4;
      &.custom-btn-disabled,
      &.custom-btn-reset-disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}

@primary-color: #0089C4;