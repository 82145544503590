.ib-date-default-wrapper,
.ib-date-range-default-wrapper {
  .ant-form-item {
    margin-bottom: 12px;

    label {
      font-size: 12px;
    }
  }

  .relative-time,
  .ant-input-number {
    width: 100%;
  }

  .ant-select {
    font-size: 12px;
  }
}

@primary-color: #0089C4;