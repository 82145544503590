.canvas-panel-container {
  &.mobile-container {
    background-color: #f0f2f5;
    overflow: hidden;

    .device-layout-container {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 8px 0;
      overflow-x: hidden;
      overflow-y: scroll;

      .device-container {
        position: relative;
        width: 351px;
        height: 709px;
        background-repeat: no-repeat;
        background-image: url(../../../../images/frame-iphone12-pro.png);
        background-size: contain;
        /* transform: scale(0.8);
        transform-origin: center top;
        transition: transform 0.5s ease 0s; */

        .device-content {
          overflow: hidden;
          transform: translate(0);
        }

        &.is-mobile-preview {
          position: relative;
          min-width: 320px;
          max-width: 540px;
          height: 100vh;
          margin: 0 auto;
          background-image: none;
          // transform: scale(1);

          .title {
            background: #fff;
            max-width: 100% !important;
            width: 100%;
            height: 42px;
            line-height: 42px;
            text-align: center;
          }
        }
      }
    }
  }
}

.dashboard-qrcode-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 20px;
  width: 200px;
  background-color: #fff;
  font-size: 12px;
  color: rgba(0,0,0,0.6);

  .lock-image {
    width: 122px;
    height: 122px;
    background-color: rgba(255,255,255,0.8);
    background: url(../../../../images/qrcode-lock.png) no-repeat;
  }

  .view-text {
    margin-top: 20px;
    text-align: center;
  }
}

@primary-color: #0089C4;