@primary-color: #0089c4;
.canvas-opt-bar {
  position: absolute;
  top: -10px;
  right: 0px;
  z-index: 9999;
  width: 40px;
  display: flex;
  background-color: @primary-color;
  opacity: 0;
  > .svg-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }
}

.canvas-opt-bar-tooltip {
  .ant-tooltip-inner {
    font-size: 12px;
  }
}

.canvas-opt-bar-dropdown-menu {
  .ant-dropdown-menu-item {
    font-size: 12px;
    .svg-icon-wrapper {
      margin-right: 4px;
    }
  }
}

@primary-color: #0089C4;