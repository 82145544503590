.detail-page {
  .detail-body {

    >* {
      margin-bottom: 15px;
    }

    .ant-tabs {
      .ant-tabs-bar {
        margin-bottom: 0;
      }

      .ant-tabs-content {
        padding: 15px;
        border: 1px solid #e8e8e8;
        border-top: none;
        border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
      }
    }

  }

  .detail-footer {
    text-align: center;
    padding-bottom: 10px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
}