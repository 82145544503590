.ud-detail {
  

  .ant-col {
    display: flex;
    min-height: 40px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .item-title {
    width: 120px;
    min-width: 120px;
    text-align: right;
    margin-right: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #858585;
    white-space: nowrap;
  }

  .item-content {
    width: 100%;
    word-break: break-all;
    color: rgba(0, 0, 0, .65);
    display: flex;
    align-items: center;
  }

  .item-content-default {
    max-height: 320px;
    overflow: auto;
  }
}