@import './variables.less';
.cube-column-item {
  .selected-column-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    height: auto;
    min-height: 36px;
    max-height: 108px;
    background-color: #f8f8fa;
    .selected-column-item-placeholder {
      position: absolute;
      top: 6px;
      z-index: 1;
      height: 24px;
      padding-left: 2px;
      line-height: 24px;
      opacity: 0.3;
    }
    .checked-column {
      z-index: 2;
      display: inline-block;
      box-sizing: border-box;
      width: 47%;
      height: 24px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 6px 6px 6px 0;
      padding-left: 5px;
      border: 1.5px solid @primary-color;
      background-color: #e2edf5;
      font-size: 12px;
      i {
        color: @dimension-color;
      }
    }
    .icon-arrow-down {
      position: absolute;
      right: 5px;
      height: 16px;
      opacity: 0.5;
    }
  }
  .select-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  ul.column-select-box {
    position: fixed;
    z-index: 101;
    width: 425px;
    background-color: #fff;
    box-shadow: 0 5px 11px rgba(25,14,46,0.09);
    li {
      display: flex;
      align-items: center;
      padding: 0 12px;
    }
    .select-search-box {
      .ant-input {
        padding: 0;
        border: 0;
        border-bottom: 1px solid #d9d9d9;
        border-radius: 0 !important;
        &:focus {
          box-shadow: none;
        }
      }
      .ant-input-affix-wrapper .ant-input-suffix {
        right: 0;
      }
    }
    .item-box {
      overflow: auto;
      height: 160px;
    }
    li.column-select-item {
      padding-top: 6px;
      height: 36px;
      .ant-checkbox {
        margin-bottom: 5px;
      }
      .ant-checkbox+span {
        padding-left: 8px;
        padding-right: 8px;
      }
      label {
        display: flex;
        align-items: center;
        width: 100%;
        height: 24px;
        line-height: 21px;
        & > span.ant-checkbox+span {
          width: calc(100% - 30px);
        }
      }
      span.column-select-name {
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-left: 4px;
        padding: 0 5px;
        border: 1.5px solid @primary-color;
        background-color: fade(@primary-color, 10%);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      i.icon-dim-color {
        color: @dimension-color;
      }
    }
  }
  i.icon-field-value-type {
    margin-right: 4px;
  }
}

@primary-color: #0089C4;