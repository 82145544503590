@import './variables.less';
.components-panel {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 48px;
  border-right: 1px solid @border-color;
  background: @bg-color;

  .components-charts,
  .components-charts-all,
  .components-charts-expand,
  .components-favourite {
    display: flex;
    align-items: center;

    .components-item {
      position: relative;
      margin: 0 2px;
      border-radius: 2px;
      & > div {
        width: 35px;
        height: 35px;
      }
    }
  }

  .components-charts,
  .components-charts-expand {
    .components-item {
      &:hover {
        background-color: hsla(0,0%,100%,0.15);
      }
    }
  }

  .components-group-item {
    width: 35px;
    height: 35px;
  }

  .component-type-icon {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 70px;
    height: 70px;
    background-image: url('../../../../images/sprite-charts.png');
    background-repeat: no-repeat;
    cursor: pointer;
    transform: scale(0.5);
    transform-origin: left top;
    &.component-type-table {
      background-position: 15px -875px;
    }
    &.component-type-kpi {
      background-position: -63px -875px;
    }
    &.component-type-line {
      background-position: -306px -1035px;
    }
    &.component-type-bar {
      background-position: -143px -875px;
    }
    &.component-type-combination {
      background-position: -463px -875px;
    }
    &.component-type-pie {
      background-position: -543px -875px;
    }
    &.component-type-map {
      background-position: -624px -955px;
    }
    &.component-type-gauge {
      background-position: -702px -875px;
    }
    &.component-type-tableMultidimensional {
      background-position: 15px -1035px;
    }
    &.component-type-area {
      background-position: -383px -875px;
    }
    &.component-type-bar-horizontal {
      background-position: -224px -875px;
    }
    &.component-type-bar-stack {
      background-position: -143px -955px;
    }
    &.component-type-bar-stack-percent {
      background-position: -143px -1035px;
    }
    &.component-type-strip-stack {
      background-position: -224px -955px;
    }
    &.component-type-strip-stack-percent {
      background-position: -224px -1035px;
    }
    &.component-type-radar {
      background-position: -783px -875px;
    }
    &.component-type-pie-rose {
      background-position: -543px -1035px;
    }
    &.component-type-treemap {
      background-position: -1103px -875px;
    }
    &.component-type-funnel {
      background-position: -1023px -875px;
    }
    &.component-type-scatter {
      background-position: -863px -955px;
    }
    &.component-type-bubble-map {
      background-position: -624px -875px;
    }
  }

  .components-charts-all {
    .components-charts-all-icon {
      display: block;
      font-size: 18px;
      cursor: pointer;
      svg {
        margin-top: 8px;
        color: #cfd6e6;
      }
    }
    .components-group-item {
      line-height: 35px;
      text-align: center;
      &:after {
        position: absolute;
        top: 14px;
        left: 32px;
        content: '';
        border-top: 6px solid #cfd6e6;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        transition: all .2s ease;
        transform: scale(.7);
      }
      &.expand {
        &:after {
          transform: scale(0.7) rotate(180deg);
        }
      }
    }
    .components-charts-all-panel {
      position: absolute;
      top: 48px;
      left: 0;
      z-index: 999;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 12px 0;
      border-right: 1px solid hsla(0,0%,85%,.15);
      background-color: #393f4d;
      .components-charts-all-separate {
        width: 1px;
        height: 22px;
        margin-top: 24px;
        background-color: hsla(0,0%,100%,.1);
      }
      .component-panel-dropdown-desc {
        width: 100%;
        padding-top: 10px;
        padding-left: 25px;
        font-size: 12px;
        color: hsla(0,0%,100%,.45);
        .text {
          padding-left: 3px;
        }
      }
    }
    .components-charts-all-group {
      display: flex;
      flex-direction: row;
      .components-charts-all-content {
        padding: 0 16px;
        .components-charts-all-title {
          width: 100%;
          text-align: center;
          font-size: 12px;
          color: hsla(0,0%,100%,.7);
          user-select: none;
        }
        .components-charts-all-items {
          display: flex;
          flex-wrap: wrap;
          .components-item {
            height: 35px;
            border-radius: 2px;
            &:hover {
              background-color: hsla(0,0%,100%,0.15);
            }
          }
        }
      }
    }
  }

  .separate {
    margin: 0 10px;
    width: 0;
    height: 20px;
    border-right: 1px solid rgba(0,0,0,0.3);
  }

  .add-query-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0,0.65);
    cursor: pointer;
    .svg-icon-wrapper {
      margin-right: 8px;
      font-size: 16px;
    }
  }

  .filter-widgets-panel {
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 999;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 12px 0;
    border-right: 1px solid hsla(0,0%,85%,.15);
    background-color: #fff;
    box-shadow: 0 1px 1px #e6e6e6;
  }
}

.guid-tip-container {
  position: relative;
  display: block;
  line-height: 1.5;
}

.components-panel-icon-container {
  height: 100%;
}

.ant-tooltip {
  &.theme-drak {
    .ant-tooltip-content {
      font-size: 12px;
    }
    .ant-tooltip-inner {
      background-color: #fff;
      color: rgba(0,0,0,0.65);
    }
    .ant-tooltip-arrow::before {
      background-color: #fff;
    }
  }
}

@primary-color: #0089C4;