@border-color: #f0f0f0;
@text-color: rgba(0,0,0,0.85);
.list-title {
    margin-bottom: 0;
    padding-left: 16px;
    line-height: 42px;
    border-bottom: 1px solid @border-color;
    font-size: 15px;
    color: @text-color;
}
.list {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      padding-right: 16px;
      padding-left: 16px;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;
      .meta {
        width: 100%;
      }
      .iconElement {
        font-size: 32px;
      }
      &.read {
        opacity: 0.4;
      }
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        background: #e6f7ff;
      }
      .title {
        font-weight: normal;
        height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .ant-list-item-meta-title {
        margin-bottom: 0;
    }
    .ant-list-item-meta-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  }

  .not-found {
    padding: 48px 0;
    color: #666;
    text-align: center;
    img {
      display: inline-block;
      height: 76px;
      margin-bottom: 16px;
    }
  }
@primary-color: #0089C4;