$primary-color: #0089C4;
$border-color: rgba(0,0,0,0.09);
.ud-sidebar {
  display: flex;
  flex-direction: column;
//   width: 180px;
//   min-width: 180px;
  flex-shrink: 0;
  background: #eaedf2;
  box-shadow: 2px 0 6px rgba(0,0,0,0.45);
  transition: all 0.2s ease;

  &.collapse {
    width: 0;
    min-width: 0;
    overflow: hidden;
  }

  .ud-sidebar-body {
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ud-sidebar-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-top: 1px solid #d9dee4;
  }
  .ant-menu-submenu-title,
  .ant-menu-item:not(:last-child) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
  }

    .ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 0 16px !important;
        width: 48px;
    }
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
        width: 5px;
        height: 1px;
    }
}

.ud-sidebar-collapse {
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 45%;
  left: calc(180px - 50px);
  transition: all 0.2s ease;
  z-index: 98;

  .ud-sidebar-collapse-bg {
    width: 100%;
    height: 100%;
    border-bottom: 12px solid transparent;
    border-left: none;
    border-right: 13px solid #d9dee4;
    border-top: 12px solid transparent;
    transition: all 0.2s ease;

    .icon {
      position: absolute;
      right: 0;
      top: 18px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &:hover {
    .ud-sidebar-collapse-bg {
      border-bottom: 12px solid transparent;
      border-left: none;
      border-right: 20px solid #d9dee4;
      border-top: 12px solid transparent;
    }
  }

  &.collapse {
    left: 0;

    .ud-sidebar-collapse-bg {
      border-bottom: 12px solid transparent;
      border-left: 14px solid #d9dee4;
      border-right: none;
      border-top: 12px solid transparent;

      .icon {
        position: absolute;
        left: -36px;
        top: 18px;
        font-weight: bold;
      }
    }

    &:hover {
      .ud-sidebar-collapse-bg {
        border-bottom: 12px solid transparent;
        border-left: 20px solid #d9dee4;
        border-right: none;
        border-top: 12px solid transparent;
      }
    }
  }
}
.sidebar-collapse {
    display: flex;
    padding: 0 16px;
    height: 40px;
    align-items: center;
    border-top: 1px solid $border-color;
    i {
        font-size: 12px;
        color: rgba(0,0,0,0.45);
        cursor: pointer;
        &:hover {
            color: $primary-color;
        }
    }
}
