.ud-toolbar {
  display: flex;
  margin-bottom: 12px;
  font-size: 12px;

  button {
    font-size: 12px;
  }
}

.ud-toolbar-other {
  flex: 1;
  margin-right: 10px;
}

.ud-toolbar-main {
  button {
    margin-left: 10px;
  }
}

.ant-affix {
  .ud-toolbar {
    background: linear-gradient(to bottom, white 0%, transparent 100%)
  }
}