.ud-filter {
//   border: 1px solid #e8e8e8;
  background: #fff;
  margin-bottom: 16px;
  border-radius: var(--border-radius-base);

  .ant-form {
    display: flex;
    flex-direction: column;

    .ant-form-item {
      display: flex;
      margin-bottom: 19px;
    }

    .ant-form-item-with-help {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      display: flex;
      justify-content: center;
      max-width: 88px;
      text-align: right;
      min-width: 88px;
      height: 40px;

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        flex-grow: 1;
      }

      span {
        flex-grow: 1;
        white-space: normal;

      }

      &::after {
        min-width: 4px;
      }
    }

    .ant-form-item-control-wrapper {
      flex: 1;
      width: 1%;

      .ant-calendar-picker {
        width: 100% !important;
      }
    }

  }

  .ud-filter-body {
    padding: 18px 22px 0 8px;
  }

  .ud-filter-footer {
    border-top: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0;

    button {
      margin: 0 5px;
      font-size: 12px;
    }
  }
}
// 过滤条件查询样式重构
.ud-filter {
    padding-top: 15px;
    padding-bottom: 0 !important;
    .ant-form {
        flex-direction: row;
    }
    .ant-select {
        min-width: 120px;
    }
}
.list-filter-opts {
    display: flex;
    .ant-input,
    .ant-select {
        width: 160px;
    }
    .ant-calendar-picker-input {
        width: 240px;
    }
}
.list-filter-actions {
    margin-top: 4px;
    margin-left: 8px;
}