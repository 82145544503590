.ud-page-header {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 15px;
  background: #fff;
}

.ud-page-header-heading {
  border-left: 2px solid var(--primary-color-palette-7);
  margin-right: 8px;
  padding-left: 8px;
  height: 15px;
  line-height: 1;
  flex: 1;
}

.ud-page-header-heading-title {
  color: #333;
  font-size: 14px;
}

.ud-page-header-heading-sub-title {
  font-size: 12px;
  margin-left: 8px;
  color: #b1b1b1;
}

.ud-page-header-extra {
  padding-right: 5px;

  i {
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
  }
}

.ud-page-header-affix {
  margin-bottom: 15px;
}