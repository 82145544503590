.component-loader {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  min-height: 10px;
  cursor: auto;

  .component-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.component-loader-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(0,0%,100%,.8);

  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 60px;
      vertical-align: middle;
      border-style: none;
    }

    .loading-text {
      color: rgba(0,0,0,.5);
    }
  }
}

.component-loader-error {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: rgba(0,0,0,.4);
  &.error-empty-notice {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    background-color: transparent;
    .error-image {
      display: none;
    }
    .error-description {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 185px;
      height: 34px;
      border: 1px solid rgba(0,0,0,.06);
      font-size: 12px;
      color: rgba(0,0,0,.5);
      opacity: 0.8;
    }
    &.line {
      background-image: url("../../../../images/line_placeholder.png");
    }
    &.area {
      background-image: url("../../../../images/area_placeholder.png");
    }
    &.bar {
      background-image: url("../../../../images/bar_placeholder.png");
    }
    &.bar-stack {
      background-image: url("../../../../images/bar_stack_placeholder.png");
    }
    &.bar-stack-percent {
      background-image: url("../../../../images/bar_stack_percent_placeholder.png");
    }
    &.bar-horizontal {
      background-image: url("../../../../images/bar_horizontal_placeholder.png");
    }
    &.strip-stack {
      background-image: url("../../../../images/strip_stack_placeholder.png");
    }
    &.strip-stack-percent {
      background-image: url("../../../../images/strip_stack_percent_placeholder.png");
    }
    &.pie {
      background-image: url("../../../../images/pie_placeholder.png");
    }
    &.pie-rose {
      background-image: url("../../../../images/pie_rose_placeholder.png");
    }
    &.combination {
      background-image: url("../../../../images/combination_placeholder.png");
    }
    &.radar {
      background-image: url("../../../../images/radar_placeholder.png");
    }
    &.treemap {
      background-image: url("../../../../images/treemap_placeholder.png");
    }
    &.kpi {
      background-image: url("../../../../images/kpi_placeholder.png");
    }
    &.gauge {
      background-image: url("../../../../images/gauge_placeholder.png");
    }
    &.funnel {
      background-image: url("../../../../images/funnel_placeholder.png");
    }
    &.scatter {
      background-image: url("../../../../images/scatter_placeholder.png");
    }
    &.table {
      background-image: url("../../../../images/cross_table_placeholder.png");
    }
    &.tableMultidimensional {
      background-image: url("../../../../images/cross_table_placeholder.png");
    }
    &.map {
      background-image: url("../../../../images/map_placeholder.png");
    }
    &.bubble-map {
      background-image: url("../../../../images/bubble_map_placeholder.png");
    }
  }

  .error-description {
    max-width: 80%;
    margin-top: 10px;
    text-align: center;
    word-wrap: break-word;
    word-break: normal;
  }

  .error-image {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .error-description {
    margin-top: 10px;
    max-width: 80%;
    text-align: center;
    word-wrap: break-word;
    word-break: normal;
  }

  &.default {
    .error-image {
      background-image: url("../../../../images/error.png");
    }
  }
}

@primary-color: #0089C4;