@import './variables.less';
.dashboard-layout {
  .dashboard-content {
    height: calc(100vh - 98px);
    overflow: auto;
  }
  &.edit {
    .dashboard-content {
      padding-bottom: 300px;
    }
  }

  .layout {
    position: relative;
    width: 100%;
    transform-style: preserve-3d;
  }

  .react-grid-item {
    transform: translateZ(10px);
    &:hover {
      .react-resizable-handle {
          visibility: visible;
      }
    }
  }
  .react-resizable-handle {
    visibility: hidden;
  }
  .react-grid-item.react-grid-placeholder {
    border: 1px dashed #c5c5c5;
    background: rgba(0,0,0,0.05);
    opacity: 1;
  }
  .component-card-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &.card-selected {
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: '';
        border: 1px solid @primary-color;
        border-radius: inherit;
        pointer-events: none;
      }
    }
  }
  .component-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    background-color: @color-white;
    &:hover {
      box-shadow: 0 6px 10px 0 @card-wrap-box-shadow-color;
      .canvas-opt-bar {
        opacity: 1;
      }
    }
    h3 {
      margin: 0;
    }
    .card-header-wrapper {
      position: relative;
      z-index: 102;
      display: flex;
      vertical-align: top;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      line-height: 1;
      i.icon-linkage,
      i.icon-linkage-effect {
        color: @primary-color;
      }
      i.icon-linkage-cancel {
        margin-left: 8px;
        font-size: 12px;
        cursor: pointer;
        &:hover {
          opacity: 0.4;
        }
      }
      i.icon-linkage-effect {
        &:hover {
          opacity: 0.4;
        }
      }
    }
    .caption-wrapper {
      position: static;
      top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: auto;
      // min-width: 40px;
      overflow: hidden;
      .caption {
        margin-right: 4px;
        padding: 4px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        color: rgb(51,51,51);
        font-weight: 600;
      }
    }
    .card-footer-wrapper {
      .ant-breadcrumb {
        font-size: 12px;
        & > span {
          .ant-breadcrumb-link {
            cursor: pointer;
          }
          &:last-child {
            .ant-breadcrumb-link {
              cursor: not-allowed;
              pointer-events: none;
            }
          }
        }
      }
    }
    .mark-wrapper {
      p {
        margin: 0;
        font-size: 12px;
        color: #000;
      }
    }
  }

  .render-engine-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .ant-table {
      border: none !important;
      box-shadow: none !important;
    }

    .table-normal-wrapper {
      padding: 0 !important;

      .table-normal-content {
        flex: 1;

        .table-normal {
          height: 100%;
        }
      }
    }

    .compare-table {
      border: 1px solid #000;
      th.ant-table-row-cell-last {
        .ant-table-column-title span {
          border-right: 0;
        }
      }
      .ant-empty-normal {
        margin: 0;
      }
    }

    .ant-spin-nested-loading,
    .ant-spin-container {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  .custom-component-container {
    padding: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    label.custom-comp-label {
      display: block;
      padding-bottom: 8px;
      padding-right: 8px;
    }
    span.custom-comp-control {
      flex: 1;
    }
  }

  .custom-button-comp {
    .custom-component-container {
      padding: 0;
    }
    .ant-btn {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.card-menu-wrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  visibility: hidden;
  i {
      display: block;
      margin-left: 8px;
      width: 12px;
      height: 12px;
      font-size: 12px;
      cursor: pointer;
      &.is-disabled {
          opacity: 0.5;
          cursor: not-allowed;
      }
  }
  .icon-drill-down {
    display: flex;
    align-items: center;
  }

  &.card-menu-hide {
    visibility: hidden;
  }

  &.card-menu-show {
    visibility: visible;
  }
}

.add-component-guid-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  width: 310px;
  height: 180px;

  img {
    width: 120px;
    height: 120px;
  }

  .start-text {
    margin-top: 12px;
    line-height: 22px;
    font-size: 14px;
  }
}

@primary-color: #0089C4;