@import './variables.less';
.setting-panel {
  position: relative;
  display: flex;
  width: 380px;
  height: 100%;
  background: @bg-color;

  .ant-tabs-tab {
    border: 0 !important;
  }

  .setting-panel-wrapper {
    width: 100%;
  }
  &.show-cube {
    z-index: 2;

    .setting-panel-wrapper {
      width: 200px;
    }
  }

  .setting-design-panel,
  .custom-setting-panel {
    position: relative;
    height: 48px;
    padding: 0 12px;
    line-height: 48px;
    border-bottom: 1px solid @border-color;
    background: @bg-color;
    font-size: 14px;
    color: @color-white;
  }

  .custom-setting-panel {
    padding: 0;
    height: 100%;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
    .ant-tabs-nav-container,
    .ant-tabs-tab,
    .ant-tabs-tab-active {
      height: 48px !important;
      line-height: 48px !important;
    }
    .ant-tabs {
      height: 100%;
    }
    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      margin: 0 16px !important;
      padding: 0 !important;
      &.ant-tabs-tab-active {
        color: @color-white;
        &:after {
          position: absolute;
          bottom: 0;
          content: '';
          width: 30px;
          border: 1px solid @primary-color;
        }
      }
    }
    .ant-form {
      label {
        font-size: 12px;
        color: rgba(0,0,0,0.6);
      }
    }
    .ant-form-item {
      margin-bottom: 12px;
      font-size: 12px;
    }
    .color-picker-wrapper {
      top: 5px;
    }
    .svg-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      cursor: pointer;
      font-size: 14px;
      &.checked {
        color: @primary-color !important;
      }
    }
    .ant-tabs-content {
      padding: 0 12px;
    }
  }

  .ui-schema-antd-tabs {
    height: calc(100vh - 98px);

    .ant-tabs-bar {
      margin: 0;
      border-bottom: 1px solid @border-color;
    }

    .ant-tabs-nav-container {
      height: 41px !important;
    }

    .ant-tabs-nav-wrap {
      background: @bg-color;
      color: hsla(0,0%,100%,.75);
    }

    .ant-tabs-nav {
      width: 100%;
      & > div {
        display: flex;
        border-bottom: 1px solid @border-color;
      }

      .ant-tabs-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 40px;
        border: none;
        background: @bg-color;
        font-size: 14px;
        &:hover {
          color: @color-white;
        }
        &.ant-tabs-tab-active {
          color: @color-white;
          &:after {
            position: absolute;
            bottom: 0;
            content: '';
            width: 30px;
            border: 1px solid @primary-color;
          }
        }
      }
    }
  }

  .svg-icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: hsla(0,0%,100%,.5);
  }

  .ant-tabs {
    .ant-tabs-content {
      position: relative;
      // overflow: auto;
      height: calc(100% - 40px);

      .setting-panel-tab {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.change-chart-type-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .chart-type {
    display: flex;
  }

  .component-type-icon {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 70px;
    height: 70px;
    background-image: url('../../../../images/sprite-charts.png');
    background-repeat: no-repeat;
    cursor: pointer;
    transform: scale(.65);
    transform-origin: left top;
    flex-shrink: 0;

    &.component-type-table {
      background-position: 15px -875px;
    }
    &.component-type-kpi {
      background-position: -63px -875px;
    }
    &.component-type-line {
      background-position: -306px -1035px;
    }
    &.component-type-bar {
      background-position: -143px -875px;
    }
    &.component-type-bar-stack {
      background-position: -143px -955px;
    }
    &.component-type-bar-stack-percent {
      background-position: -143px -1035px;
    }
    &.component-type-bar-horizontal {
      background-position: -224px -875px;
    }
    &.component-type-strip-stack {
      background-position: -224px -955px;
    }
    &.component-type-strip-stack-percent {
      background-position: -224px -1035px;
    }
    &.component-type-combination {
      background-position: -463px -875px;
    }
    &.component-type-pie {
      background-position: -543px -875px;
    }
    &.component-type-map {
      background-position: -624px -955px;
    }
    &.component-type-gauge {
      background-position: -702px -875px;
    }
    &.component-type-tableMultidimensional {
      background-position: 15px -1035px;
    }
    &.component-type-area {
      background-position: -383px -875px;
    }
    &.component-type-radar {
      background-position: -783px -875px;
    }
    &.component-type-pie-rose {
      background-position: -543px -1035px;
    }
    &.component-type-treemap {
      background-position: -1103px -875px;
    }
    &.component-type-funnel {
      background-position: -1023px -875px;
    }
    &.component-type-scatter {
      background-position: -863px -955px;
    }
    &.component-type-bubble-map {
      background-position: -624px -875px;
    }
  }

  .more-chart-span {
    display: flex;
    align-items: center;
    height: 48px;
    padding-left: 12px;
    color: hsla(0,0%,100%,.7);

    .component-type-icon {
      margin-right: -40px;
      transform: scale(.4);
      transform-origin: left center;
    }

    .chart-name-span {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
    }
  }
}

.style-panel-tab {
  padding: 12px 6px;
  overflow-y: auto;
  .title-selector-wrapper,
  .end-value-selector-wrapper,
  .kpi-addon-selector-wrapper,
  .style-axis-item {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-label > label {
      font-size: 12px;
      color: @color-white;
      &::after {
        margin-right: 4px;
      }
    }
  }
  .color-selector-wrapper {
    .attr-item {
      padding: 0;
      flex-direction: column;
      align-items: center;
      .attr-item-label {
        padding-right: 0;
        width: 48px;
        align-self: flex-start;
      }
    }
  }
  .form-item-mark-box {
    .ant-form-item-control {
      line-height: 30px;
    }
    i.icon-edit {
      margin-left: 4px;
      cursor: pointer;
    }
  }
  .form-item-mark-content,
  .form-item-mark-position {
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
      pointer-events: none;
    }
    label {
      font-size: 12px;
    }
  }
  .form-item-mark-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .form-item-mark-position {
    span.ant-radio + * {
      padding-left: 2px;
      padding-right: 2px;
    }
    .ant-radio-wrapper {
      margin-right: 0;
    }
    .ant-radio-inner {
      width: 14px;
      height: 14px;
      &::after {
        width: 6px;
        height: 6px;
      }
    }
    & > label {
      display: block;
    }
  }
  .style-axis-item {
    .style-axis-item-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 32px;
    }
    .svg-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      cursor: pointer;
      font-size: 14px;
      &.checked {
        color: @primary-color !important;
      }
    }
  }
}

.advanced-panel-tab {
  padding: 12px 10px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    & > label {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: @color-white;
      &::after {
        margin-right: 4px;
      }
    }
    span {
      font-size: 12px;
    }
  }
  .ant-form-item-control-wrapper {
    text-align: right;
  }
  .senior-config-item-container {
    display: flex;
    flex-grow: 3;
    flex-basis: 0;
    flex-direction: column;
    width: 100%;
    min-height: min-content;
    &:not(svg):not(path) {
      font-size: 12px !important;
    }
  }
  .auxiliary-line-container {
    margin: 4px 0;
  }
  .senior-config-common-title {
    .advanced-set {
      position: relative;
      display: flex;
      justify-content: space-between;
      i {
        cursor: pointer;
      }
    }
  }
  .advanced-set-list {
    margin: 5px 0;
    padding-left: 16px;
    .advanced-set-list-item {
      display: flex;
      opacity: 0.8;
      & > div {
        padding-right: 5px;
        width: 30%;
      }
      & > div.item-name {
        width: 40%;
      }
    }
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
  }
}

@primary-color: #0089C4;