@primary-color: #0089c4;
@color-white: #fff;
@border-color: rgba(0,0,0,0.09);
.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-size: 14px;
}
.header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
    width: 100vw;
    height: 50px;
    background: @primary-color;
    color: @color-white;
    .main-hd-right {
        height: 50px;
        line-height: 50px;
    }
}
.app-menus {
    position: absolute;
    top: 0;
    left: 320px;
}
.app-list {
    display: flex;
    margin: 0;
    .item-app {
        padding: 0 16px;
        line-height: 50px;
        font-size: 15px;
        color: @color-white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &.is-active,
        &:hover {
            color: @primary-color;
            background-color: #e6f7ff;
        }
    }
}
.main-wrap {
    display: flex;
    flex: 1;
    flex-direction: row;
}
.sidebar {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    background-color: @color-white;
    // box-shadow: 2px 0 6px #efefef;
    transition: all 0.2s ease;
    .sidebar-body {
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .sidebar-footer {
        align-items: center;
        justify-content: center;
        padding: 15px;
        border-top: 1px solid #d9dee4;
        i {
            font-size: 12px;
            color: rgba(0,0,0,0.45);
            cursor: pointer;
            &:hover {
                color: @primary-color;
            }
        }
    }
    .ant-menu-inline {
        border-right: 0;
    }
    .ant-menu-submenu-title,
    .ant-menu-item:not(:last-child),
    .ant-menu-inline .ant-menu-item {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 0 16px !important;
        width: 48px;
    }
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
        width: 5px;
        height: 1px;
    }
    .ant-menu {
        &:not(.ant-menu-horizontal) {
            .ant-menu-item-selected {
                background-color: #e6f7ff;
                color: #0089c4;
                border-right: 2px solid #0089c4;
            }
        }
        &.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
        .ant-menu-sub.ant-menu-inline > .ant-menu-item {
            height: 40px;
            line-height: 40px;
        }
        .ant-menu-item {
            color: #535353;
            height: 40px;
            line-height: 40px;
            &:hover {
                background-color: #e6f7ff;
                color: #0089c4;
            }
        }
        .ant-menu-submenu-title {
            color: #535353;
            &:hover {
                background-color: #e6f7ff;
                color: #0089c4;
            }
        }
        .ant-menu-submenu {
            > .ant-menu {
            background-color: #fff;
            // box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
            .ant-menu-item {
                color: #535353;
                &:hover,
                &.ant-menu-item-selected {
                    color: #0089c4;
                }
            }
            }
            .ant-menu-submenu-arrow {
                &::before,
                &::after {
                    background-color: rgba(0, 0, 0, 0.45);
                    background-image: none;
                }
            }
        }
    }
    .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
            color: #0089c4;
        }
    }
    .tag-coming-soon {
        background: #1890ff;
        color: #fff;
        display: inline-block;
        margin-left: 8px;
        text-align: center;
        padding: 1px 4px;
        border-radius: 2px;
        font-size: 10px;
        height: 20px;
        line-height: 20px;
        transform: scale(0.7);
      }
}
.content-wrap {
    flex: 1;
    padding: 0 15px;
    background: #f7faff;
    overflow-y: auto;
    overflow-x: hidden;
}
.ant-back-top {
    right: 40px;
    bottom: 40px;
}

.guide-app,
.guide-app-menus,
.guide-help {
  width: 300px;
  .guide-modal-content {
    font-size: 13px;
  }
  .guide-modal-footer-btn {
    background: @primary-color;
    border-radius: 3px;
    &:hover {
      background: #21a2d1;
    }
  }
}
.guide-app {
  .guide-modal-title {
    display: none;
  }
}
.guide-mask {
  width: 100vw !important;
  &:after {
    border-radius: 0;
  }
}

@primary-color: #0089C4;