$color-white: #fff;
$border-color: rgba(0, 0, 0, 0.09);
$text-color-primary: #000;
$primary-color: #0089c4;
// 原子类
.mh-16 {
  margin: 0 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.no-padding {
  padding: 0 !important;
}
// antd-ui style reset
// 面包屑
.breadcrumb-wrap {
  padding: 15px 0;
}
// 过滤搜索
.list-filter {
  position: relative;
  width: 100%;
  background: $color-white;
  ul {
    margin: 0;
  }
}
.list-filter-row {
  padding: 16px 0;
  border-bottom: 1px solid $border-color;
  &:last-child {
    border-bottom: 0;
  }
}
.list-filter-row-top {
  padding: 16px;
  .item-filter-option {
    display: inline-block;
  }
}
.item-filter-option {
  display: flex;
  label {
    display: inline-block;
    margin-right: 8px;
    color: $text-color-primary;
  }
  .item-filter-option-list {
    display: flex;
    li {
      padding: 0 16px;
      cursor: pointer;
      &.is-active {
        color: $primary-color;
      }
    }
  }
  .ant-calendar-picker-input {
    width: 240px;
  }
}
// 卡片
.item-card {
  position: relative;
  .ant-card-body {
    padding: 16px;
  }
  .top-left-triangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-style: solid dashed dashed;
    border-width: 24px 24px 0 0;
    border-color: red transparent transparent;
    display: none;
    i {
      position: absolute;
      top: -22px;
      left: 1px;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
    }
  }
  .top-right-actions {
    position: absolute;
    top: 16px;
    right: 16px;
    display: none;
    i {
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      font-size: 16px;
      color: #333;
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    .top-left-triangle,
    .top-right-actions {
      display: block;
    }
  }
}
.action-more-list {
  margin-bottom: 0;
  li {
    line-height: 24px;
    font-size: 12px;
    color: #333;
    cursor: pointer;
    i {
      display: inline-block;
      vertical-align: -2px;
      margin-right: 4px;
    }
    &:hover {
      color: $primary-color;
    }
  }
}
// 监控预警设置弹窗
.dlg-warning-setting {
  .ant-modal-body {
    padding: 0;
  }
}

// 详情页
.page-detail-wrap {
  position: relative;
  width: 100%;
  .detail-header {
    height: 70px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $color-white;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.05);
  }
  .detail-actions {
    padding: 0 15px;
    button {
      margin-right: 8px;
    }
  }
  .detail-table-wrap {
    padding: 15px;
  }
}
// antd override
.ant-tree {
  li {
    .ant-tree-node-content-wrapper {
      &:hover {
        background-color: #f5f5f5 !important;
      }
      &.ant-tree-node-selected {
        background-color: $primary-color !important;
        color: #fff;
      }
    }
  }
}
.ant-modal-confirm-body {
  .ant-modal-confirm-title {
    font-size: 14px !important;
  }
  .ant-modal-confirm-content {
    font-size: 12px !important;
  }
}
