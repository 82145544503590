.contrast-setting-form-wrapper {
  .ant-form {
    label {
      font-size: 12px;
    }
  }
  .ant-select {
    font-size: 12px;
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.select-opt-wrapper {
  .ant-select-dropdown-menu-item {
    font-size: 12px;
  }
}

@primary-color: #0089C4;