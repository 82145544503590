@dimension-color: #0089c4;
@measure-color: #229445;

.modal-granularity-field-filter-wrapper {
  .ant-modal-header,
  .ant-modal-body,
  .ant-modal-footer {
    background-color: #f8f8f8;
  }
  .ant-modal-body {
    padding: 20px;
  }
}
.filter-model-content {
  font-size: 12px;
  color: #333;
}

.filter-model-content-header {
  display: flex;
  height: 36px;
  gap: 14px;

  .header-item {
    display: flex;
    height: 24px;
    align-items: center;
    flex: 1;

    .header-item-label {
      margin-right: 10px;
      font-size: 12px;
    }

    .header-item-value,
    .field-item {
      display: flex;
      flex: 1;
      align-items: center;
      background-color: #fff;
      height: 24px;
      padding: 0 8px;
    }

    .svg-icon-wrapper {
      margin-right: 6px;
    }

    .icon-cube {
      color: #a186ff;
    }

    .field-item {
      border: 1px solid transparent;
      .svg-icon-wrapper {
        font-size: 14px;
      }
      &.dimension {
        border-color: @dimension-color;
        background: fade(@dimension-color, 10%);
        .svg-icon-wrapper {
          color: @dimension-color;
        }
      }
      &.measure {
        border-color: @measure-color;
        background: fade(@measure-color, 10%);
        .svg-icon-wrapper {
          color: @measure-color;
        }
      }
    }
  }
}

.filter-model-content-info {
  padding: 20px;
  background-color: #fff;
}

.filter-datetime-config {
  .form-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 6px;

    .form-item-label {
      width: 72px;
      height: 28px;
      line-height: 28px;
      color: #616161;
    }

    .form-item-value {
      flex: 1;
      line-height: 28px;
    }

    .ant-radio-wrapper {
      font-size: 12px;
      color: #262626;
    }
  }
}

.filter-datetime-config-duration {
  display: flex;
  position: relative;
  margin-top: 4px;
}

.filter-datetime-config-duration-atom {
  width: 50%;
}

.time-config-wrap {
  padding-top: 42px;
  width: 190px;
}

.filter-datetime-config-duration-line {
  position: absolute;
  top: 10px;
  left: 6px;
  width: 50%;
  height: 6px;
  background-image: linear-gradient(90deg, #6ca0f5, #ff7474);
}

.filter-datetime-config-duration-tag {
  position: absolute;
  top: -3px;
  width: 12px;
  height: 12px;
  border: 4px solid;
  border-radius: 10px;
  background-color: #fff;
  span {
    position: relative;
    top: 11px;
    left: -4px;
    display: block;
    width: 80px;
    line-height: 16px;
  }
  &.start {
    left: -6px;
    border-color: #6ca0f5;
  }
  &.end {
    right: -6px;
    border-color: #ff7474;
  }
}

.react-datepicker__navigation-icon {
  line-height: 10px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
}
.react-datepicker__input-container {
  input {
    padding: 0 4px;
    width: 100%;
    border: 1px solid #d9d9d9;
    font-size: 12px;
    border-radius: 4px;
    &:focus,
    &:focus-visible {
      border: 1px solid @dimension-color;
      outline: 0;
    }
  }
}
.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  background-color: @dimension-color;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  line-height: 20px;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 56px;
  height: 21px;
  line-height: 21px;
}

@primary-color: #0089C4;