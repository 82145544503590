@import './variables.less';
.data-panel-cube-wrapper {
  position: relative;
  z-index: 1;
  width: 180px;
  max-width: 240px;
  border-left: 1px solid @border-color;
  background-color: #242833;
  color: @color-white;

  .data-panel-cube-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    line-height: 48px;
    padding: 0 12px;
    background-color: #242833;
    font-size: 12px;
    color: @color-white;
  }

  .data-panel-content {
    height: calc(100% - 48px);
  }

  .cube-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 8px 8px;

    .select {
      flex: 1;
    }

    .icon-sync-field-info {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .data-panel-cube-tree-wrapper {
    height: calc(100% - 40px);
  }

  .tree-wrapper {
    position: relative;
    z-index: 2;
    height: calc(100% - 32px);

    & > div {
      height: 50%;
      max-height: calc(100% - 30px);
      overflow-y: auto;
      &:first-child {
        border-bottom: 1px solid @border-color;
      }
    }
  }

  .data-panel-tree {
    position: relative;
    height: 100%;
    overflow: hidden;

    .cube-tree-search {
      padding: 4px 8px;
    }

    .data-panel-dimension-tree,
    .data-panel-measure-tree {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: #242833;

      .data-panel-tree-title {
        display: flex;
        padding: 8px 12px;
        padding-bottom: 4px;
        font-size: 12px;
        color: @color-white;
      }

      .field-tree {
        padding: 8px 12px;
        flex-grow: 1;
        overflow-y: auto;
        padding-top: 0;
        font-size: 12px;
        & > li {
          padding: 2px 0;
          &:first-child {
            padding-top: 0;
          }
        }
        .ant-tree-switcher {
          width: 8px;
          color: hsla(0,0%,100%,.5);
        }
        .ant-tree-node-content-wrapper {
          display: inline-block;
          padding: 0 2px;
          width: calc(100% - 8px);
          height: 24px;
          // border: 1px solid transparent;
          color: rgba(0,0,0,.65);
          cursor: pointer;
          transition: all .3s;
          &:hover {
            background-color: hsla(0,0%,100%,.1) !important;
          }
        }
        &.field-tree-dimension {
          .svg-icon-wrapper {
            color: @dimension-color;
          }
        }
        &.field-tree-measure {
          .svg-icon-wrapper {
            color: @measure-color;
          }
        }
        .ant-tree-node-selected {
          background-color: transparent !important;
        }
      }
    }

    .data-panel-dimension-tree {
    }

    .field-tree-attr {
      padding: 2px 0;
    }

    .field-tree-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid transparent;
      color: hsla(0,0%,100%,.75);

      .field-tree-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;

        .field-tree-text {
          margin-left: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .svg-icon-wrapper {
        display: flex;
        align-items: center;
        height: 12px;
        font-size: 12px;
      }
    }

    .cube-loading-spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 3;
      max-width: 80%;
      padding: 8px;
      border-radius: 2px;
      background: hsla(0,0%,100%,.9);
      font-size: 12px;
      .ant-spin-dot {
        font-size: 14px;
      }
    }
  }
}

@primary-color: #0089C4;