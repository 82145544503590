.export-task {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 12px;
}
.popover {
    position: relative;
    width: 240px;
}
.noticeButton {
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s;
}
.icon {
    padding: 4px;
    vertical-align: middle;
}
.badge {
    font-size: 16px;
}


@primary-color: #0089C4;