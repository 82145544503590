body {
  //background-color: rgba(6, 18, 37, 1) !important;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.login-page-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(6, 18, 37, 1);
  background-image: url('../../assets/login_bg.png');
  background-repeat: no-repeat;
  overflow: hidden;
}

.login-form-content {
  position: absolute;
  right: 5%;
  top: 19.5vh;
  width: 480px;
  .login-page-logo {
    width: 274px;
    height: 60px;
    display: block;
    margin: 0 auto;
  }

  .login-form {
    width: 480px;
    height: 390px;
    border-radius: 12px;
    transform: scale(0.9);
    background: rgba(255, 255, 255, 1);

    .login-form-title {
      position: relative;
      width: 96px;
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      line-height: 33px;
      top: 30px;
      left: 192px;
    }

    .login-form-item {
      position: relative;
      left: 60px;
      top: 56px;
      width: 360px;

      .login-form-input {
        height: 48px;
        background: rgba(255, 255, 255, 1);
        border-radius: 5px;
        // border: 1px solid rgba(0, 137, 196, 1);
      }

      .login-form-btn {
        width: 360px;
        height: 50px;
        background: rgba(0, 137, 196, 1);
        border-radius: 5px;
      }
    }

    .form-item-captcha-wrapper {
      position: relative;
      img {
        position: absolute;
        top: 1px;
        right: 1px;
        z-index: 10;
        height: 46px;
      }
    }
  }

  .copy-right {
    text-align: center;
    position: fixed;
    bottom: 10px;
    left: 50%;
    color: #fff;
    transform: translateX(-50%);
  }
}

@primary-color: #0089C4;