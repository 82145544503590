.ud-form {
  padding: 15px;

  .ud-form-footer {}
}

.ud-advanced-form {
  padding-bottom: 15px;

  .ud-advanced-form-body {
    &>* {
      margin-bottom: 15px;
    }
  }

  .ant-card-form-items {
    .ant-card-body {
      padding: 26px 26px 0 26px;
    }
  }

  .ud-advanced-form-footer {
    text-align: center;
  }
}
