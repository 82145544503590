
.ud-number-range {
  display: flex;
  align-items: center;
  .ant-input-number {
    flex: 1;
    width: 100%;
  }
  .ud-number-range-divider {
    margin: 0 8px;
  }
}