.filter-button-dialog {
  .ant-form-item {
    margin-bottom: 12px;

    label,
    .ant-input,
    .ant-select {
      font-size: 12px;
    }

    .ant-form-extra {
      display: inline-flex;
      margin: 0;
      margin-left: 6px;
      font-size: 12px;
      .form-item-tip {
        &.warning {
          color: #f80;
        }
      }
    }
  }
}

@primary-color: #0089C4;