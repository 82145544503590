.mixin-mark-body-wrapper () {
  flex: 1;
}
.mixin-mark () {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .caption-wrapper {
    position: static;
    top: 0;
    padding: 8px 12px;
    width: auto;
    overflow: hidden;
    .caption {
      .icon-info {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        font-size: 12px;
      }
    }
  }

  .mark-wrapper {
    p {
      margin: 0;
      padding: 4px 12px;
      font-size: 12px;
      color: #fff;
    }
  }
}
