@import './variables.less';
.relation-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  font-size: 12px;
  color: hsla(0, 0%, 0%, 0.6);

  .state {
    display: flex;
    align-items: center;
    .has-setting {
      display: inline-flex;
      margin-right: 4px;
      i {
        margin-left: 4px;
      }
    }
    .svg-icon-wrapper,
    .set-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.linkage-modal,
.relation-chart-tip {
  * {
    font-size: 12px;
  }
  .ant-modal-body {
    background: #f8f8fa;
  }
  i.icon-cube {
    margin-right: 6px;
    color: #ae6ffa;
  }
  .linkage-modal-header {
    display: flex;
    align-items: center;
    padding: 0 23px;
    h3 {
      float: left;
      margin-bottom: 0;
      font-size: 18px;
      color: #686868;
      font-weight: 100;
    }
    span.label {
      margin-left: 21px;
      font-size: 12px;
      opacity: 0.5;
    }
    span.result-label {
      width: 147px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      opacity: 0.9;
    }
    i.icon-cube {
      margin-left: 10px;
      margin-right: 6px;
      color: #ae6ffa;
    }
  }
  .main-chart {
    width: 100%;
    height: 100%;
    background-color: #fff;
    .ant-tabs {
      color: #000;
    }
    .ant-tabs-extra-content {
      float: left !important;
    }
    .tab-link-chart {
      display: inline-block;
      width: 130px;
      padding-left: 15px;
      text-align: left;
      color: rgba(0,0,0,0.65);
    }
    .main-chart-body {
      overflow: auto;
      height: 100%;
      .main-chart-box {
        height: auto;
        padding: 0 13px 13px;
        p.chart-num {
          opacity: 0.5;
        }
        .ds-header {
          height: 33px;
          line-height: 33px;
          border-bottom: 1px solid rgba(0,0,0,0.1);
        }
        ul.chart-column-box {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          height: auto;
          li.chart-column {
            width: 50%;
            padding-top: 10px;
          }
          .icontext {
            position: relative;
            display: inline-flex;
            align-items: center;
            min-height: 18px;
            .chart-name {
              display: inline-block;
              padding-left: 30px;
              width: 190px;
              min-height: 18px;
              line-height: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          li.diff-chart-column {
            position: relative;
            margin-top: 21px;
            width: 100%;
            div.cube-box {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              margin-top: 8px;
              & > div {
                height: 36px;
                background-color: #f8f8fa;
                color: #000;
              }
              div.cube-label {
                margin-right: 8px;
                padding: 8px;
                width: 33%;
              }
              div.cube-column-item {
                width: 65%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
  .hide {
    display: none;
  }
  .no-chart {
    height: 300px;
    background: url('../../../../assets/empty.png') no-repeat 50%;
    background-size: 30% 30%;
    span {
      position: relative;
      top: 75%;
      left: 45%;
      opacity: 0.6;
    }
  }
}

.linkage-wrapper {
  width: 100%;
  height: 100%;
  .main-container {
    display: flex;
    width: 100%;
    height: 415px;
    .item-container {
      display: flex;
      flex-direction: column;
      width: 240px;
      .add-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 37px;
        line-height: 37px;
        padding-right: 10px;
        span.item-title {
          opacity: 0.5;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        i.icon-add-circle {
          float: right;
          font-size: 12px;
          font-style: normal;
          color: @primary-color;
          cursor: pointer;
        }
      }
      .selecter-container {
        flex-grow: 3;
        overflow-y: auto;
      }
      .item-content {
        &.active {
          padding: 4px;
          border: 1px solid @primary-color;
        }
        i {
          display: inline-block;
          margin: 0 6px;
          color: rgba(0,0,0,0.5);
          cursor: pointer;
        }
        margin: 0 10px 8px 0;
        height: 36px;
        line-height: 36px;
        padding: 5px;
        background-color: #fff;
        .selected-box {
          position: relative;
          display: flex;
          width: 100%;
          height: 26px;
          line-height: 26px;
          .selected-item {
            flex: 1;
            margin-right: 10px;
            .ant-select {
              width: 100%;
            }
            .ant-select-selection {
              border: 0;
            }
            .ant-select-focused .ant-select-selection {
              box-shadow: none;
              border-right-width: 0 !important;
            }
            .ant-select-selection-selected-value {
              width: 100%;
              max-width: 160px;
              background: fade(@dimension-color, 10%);
              display: flex;
              i.icon-field-value-type {
                color: @primary-color;
              }
            }
            .ant-select-arrow {
              right: 0;
            }
          }
        }
      }
    }
    .chart-container {
      display: inline-block;
      width: calc(100% - 242px);
      height: 100%;
    }
  }
}

.selected-item-dropdown {
  .ant-select-dropdown-menu-item {
    font-size: 12px;
    i.icon-field-value-type {
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
      font-size: 12px;
      color: @primary-color;
    }
  }
}

@primary-color: #0089C4;