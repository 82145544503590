.advanced-line-container {
  .advanced-line-group {
    display: flex;
    margin-top: 15px;
    .advanced-line-item {
      margin: 0 5px;
      line-height: 28px;
    }
    .line-color {
      .color-picker-wrapper {
        top: 1px;
        & > div {
          padding: 8px 4px !important;
        }
      }
    }
    .line-style {
      .ant-select-selection-selected-value {
        width: 100%;
      }
      .line-style-result {
        display: flex;
        align-items: center;
        .line-border {
          flex-grow: 1;
          height: 1px;
          margin-left: 6px;
          border-width: 1.5px;
        }
      }
    }
    i.icon-delete {
      cursor: pointer;
    }
  }
}

@primary-color: #0089C4;