.ud-table {
  .ant-table {
    table-layout: auto;
    .ant-table-content {
      overflow: auto;
    }
  }

  .td-operate {
    a {
      margin: 0 5px;
    }

    a:first-child {
      margin-left: 0;
    }

    a:last-child {
      margin-right: 0;
    }
  }
}

.ud-table-column-customize {
  .item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
