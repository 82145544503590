.main-hd-right{
  // float: right;
  // line-height: 64px;
  .right-item{
    // padding: 0 20px;
    display: inline-block;
    font-size: 18px;
    &.ant-badge{
      font-size: 18px;
    }
  }
  .ud-main-hd-bell{
    font-size: 18px;
  }
  .hd-user{
    font-size: inherit;
    vertical-align: top;
    .hd-user-name{
      margin-left: 6px;
      cursor: pointer;
    }
    .ant-avatar {
      background-color: #00c1de !important;
      background-color: var(--primary-color) !important;
    }
    .anticon-down {
      margin-left: 2px;
      color: #00c1de;
      color: var(--primary-color);
      font-size: 14px !important;
    }
  }

  .license-tag {
    margin-left: 8px;
    cursor: pointer;
  }
}
.verification-code-item {
  display: flex;
  align-items: center;
  .get-vercode-btn {
    button {
      margin-left: 10px;
      width: 120px;
      padding: 0;
    }
  }
}